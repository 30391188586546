:root {
    --default-color: #000;
    --primary-color: #fff;
    --secondary-color: #fbe41f;
}
* {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}
h1 {
  font-size: 4em;
}
h2 {
  font-size: 2em;
}
h3 {
  font-size: 2em;
}
p {
  font-size: 1.2em;
}
@media screen and (max-width: 1300px) {
  h1 {
    font-size: 3em;
  }
}
@media screen and (max-width: 700px) {
  h1 {
    font-size: 2.5em;
  }
  p {
    font-size: 1em;
  }
  h2 {
    font-size: 2em;
  }
  h3 {
    font-size: 1.5em;
  }
}
@media screen and (max-width: 500px) {
  h1 {
    font-size: 2em;
  }
  h3 {
    font-size: 1em;
  }
  p {
    font-size: 0.8em;
  }
}
@media screen and (max-width: 400px) {
  h2 {
    font-size: 1.2em;
  }

  p {
    font-size: 0.8em;
  }
}